import * as React from 'react';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import { graphql } from 'gatsby';
import TermsAndConditionsPage from 'components/pages/termsAndConditions';
import NewLayout from 'components/NewLayout';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';

const pageUrl = `${PREFIX_CANONICAL_URL}/terms-of-use`;

const TermAndCondiTionPage = () => {
  return (
    <LanguageProvider messages={translation}>
      <NewLayout headerStyle="light">
        <BreadcrumbList
          pageLabelId="structuredData.termsOfUse"
          pageUrl={pageUrl}
        />
        <TermsAndConditionsPage />
      </NewLayout>
    </LanguageProvider>
  );
};
export default TermAndCondiTionPage;

export const Head = ({
  data,
}: {
  data: {
    allStrapiTermAndCondition: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiTermAndCondition.nodes[0]}
      canonicalURL={pageUrl}
    />
  );
};

export const query = graphql`
  query {
    allStrapiTermAndCondition {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
