import React, { useEffect } from 'react';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import TermsAndConditionsHero from './TermsAndConditionsHero';
import TermsAndConditions from './TermsAndConditions';
import LayoutContainer from 'components/LayoutContainer';
import { graphql, useStaticQuery } from 'gatsby';

const TermsAndConditionsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const queryData = useStaticQuery(graphql`
    query {
      allStrapiTermAndCondition {
        nodes {
          PageDescription
          bottomBoxHeading
          bottomBoxDescription
          bottomBoxButtonLabel
          bottomBoxButtonLink
          details {
            description {
              data {
                description
              }
            }
            title
            id
          }
        }
      }
    }
  `);
  const {
    PageDescription,
    details,
    bottomBoxHeading,
    bottomBoxDescription,
    bottomBoxButtonLabel,
    bottomBoxButtonLink,
  } = queryData.allStrapiTermAndCondition.nodes[0];
  return (
    <LanguageProvider messages={translation}>
      <div className="bg-accent_grey_1">
        <LayoutContainer className="lg:pb-[60px]">
          <TermsAndConditionsHero />
          <TermsAndConditions
            details={details}
            pageDescription={PageDescription}
            bottomBoxHeading={bottomBoxHeading}
            bottomBoxDescription={bottomBoxDescription}
            bottomBoxButtonLabel={bottomBoxButtonLabel}
            bottomBoxButtonLink={bottomBoxButtonLink}
          />
        </LayoutContainer>
      </div>
    </LanguageProvider>
  );
};

export default TermsAndConditionsPage;
